import React from 'react'
import { Helmet } from 'react-helmet'
import Layout from '../components/layout'
import kenaf from '../assets/images/kenaf.jpg'
import verti from '../assets/images/verti.jpeg'
import greenvertical from '../assets/images/greenvertical.png'
import electronics from '../assets/images/electronics.jpeg'
import drone from '../assets/images/drone.jpeg'
import carbondioxide from '../assets/images/carbondioxide.jpeg'

const Tech = props => (
    <Layout>
        <Helmet>
            <title> Tech &amp; Products</title>
            {/* <meta name="products and tech" content="products page"/> */}
        </Helmet>
        <div id="main" className="wrapper style1">
            <div className="container">
                <header className="major">
                    <h2>Our Products and Technologies</h2>
                    <p>At the forefront of Sustainability Technologies</p>
                </header>
                <section>
                    <div className="box alt">
                        <div className="row gtr-50 gtr-uniform">
                            <div href ="#" className="col-4 col-6-xsmall">
                                <b><a href="/tech/fibre">Allied Fibres Project</a></b>
                                <span className="image fit">
                                    <img href="/tech/kenaf"src={kenaf} alt="" />
                                </span>
                                
                            </div>
                            <div className="col-4 col-6-xsmall">
                                <b><a href="/tech/vertistacks">VertiStacks</a></b>
                                <span className="image fit">
                                    <img href="/tech/vertistacks" src={verti} alt="" />
                                </span>
                            </div>
                            <div className="col-4 col-6-xsmall">
                                <b><a href="/tech/carboncrediting">Carbon Crediting</a></b>
                                <span className="image fit">
                                    <img href="/tech/carboncrediting" src={carbondioxide} alt="" />
                                </span>
                            </div>
                        </div>
                    </div>
                </section>
                <br></br>
                <section>
                    <div className="box alt">
                        <div className="row gtr-50 gtr-uniform">
                            <div className="col-4 col-6-xsmall">
                                <b><a href="/tech/capstone">Capstone Project</a></b>
                                <span className="image fit">
                                    <img src={greenvertical} alt="" />
                                </span>
                            </div>
                            <div className="col-4 col-6-xsmall">
                                <b><a href="/tech/software">Software Monetization</a></b>
                                <div></div>
                                <span className="image fit">
                                    <img src={electronics} alt="" />
                                </span>
                            </div>
                            <div className="col-4 col-6-xsmall">
                                <b><a href="/tech/drones">Drone Technology</a></b>
                                <span className="image fit">
                                    <img src={drone} alt="" />
                                </span>
                            </div>
                        </div>   
                    </div>
                </section>
            </div>
        </div>
    </Layout>
)

export default Tech
